import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export const firebaseClientConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_ADMIN_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_CLIENT_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_CLIENT_FIREBASE_MESAUREMENT_ID,
};

const clientFirebase = firebase.initializeApp(firebaseClientConfig, 'client');

const clientFirestore = clientFirebase.firestore();
const clientAuth = clientFirebase.auth();
const isFirebaseSupported = () => firebase.messaging.isSupported();

export { clientFirestore, clientAuth, clientFirebase, isFirebaseSupported };
