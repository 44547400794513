export const MESSAGE_CLEAN_REQUEST = 'MESSAGE_CLEAN_REQUEST';
export const SINGLE_MESSAGE_CLEAN_REQUEST = 'SINGLE_MESSAGE_CLEAN_REQUEST';

export const MESSAGE_ADD_REQUEST = 'MESSAGE_ADD_REQUEST';
export const MESSAGE_ADD_REQUEST_SUCCESS = 'MESSAGE_ADD_REQUEST_SUCCESS';
export const MESSAGE_ADD_REQUEST_FAILURE = 'MESSAGE_ADD_REQUEST_FAILURE';

export const MESSAGE_FETCH_REQUEST = 'MESSAGE_FETCH_REQUEST';
export const MESSAGE_FETCH_REQUEST_SUCCESS = 'MESSAGE_FETCH_REQUEST_SUCCESS';
export const MESSAGE_FETCH_REQUEST_FAILURE = 'MESSAGE_FETCH_REQUEST_FAILURE';

export const MESSAGE_FIELDS_FETCH_REQUEST = 'MESSAGE_FIELDS_FETCH_REQUEST';
export const MESSAGE_FIELDS_FETCH_REQUEST_SUCCESS = 'MESSAGE_FIELDS_FETCH_REQUEST_SUCCESS';
export const MESSAGE_FIELDS_FETCH_REQUEST_FAILURE = 'MESSAGE_FIELDS_FETCH_REQUEST_FAILURE';

export const MESSAGE_TEMPLATE_CLEAN_REQUEST = 'MESSAGE_TEMPLATE_CLEAN_REQUEST';
export const MESSAGE_TEMPLATE_UPDATE_CLEAN_REQUEST = 'MESSAGE_TEMPLATE_UPDATE_CLEAN_REQUEST';

export const MESSAGE_TEMPLATE_DROPDOWN_FETCH_REQUEST = 'MESSAGE_TEMPLATE_DROPDOWN_FETCH_REQUEST';
export const MESSAGE_TEMPLATE_DROPDOWN_FETCH_REQUEST_SUCCESS =
  'MESSAGE_TEMPLATE_DROPDOWN_FETCH_REQUEST_SUCCESS';
export const MESSAGE_TEMPLATE_DROPDOWN_FETCH_REQUEST_FAILURE =
  'MESSAGE_TEMPLATE_DROPDOWN_FETCH_REQUEST_FAILURE';

export const MESSAGE_TEMPLATE_FETCH_REQUEST = 'MESSAGE_TEMPLATE_FETCH_REQUEST';
export const MESSAGE_TEMPLATE_FETCH_REQUEST_SUCCESS = 'MESSAGE_TEMPLATE_FETCH_REQUEST_SUCCESS';
export const MESSAGE_TEMPLATE_FETCH_REQUEST_FAILURE = 'MESSAGE_TEMPLATE_FETCH_REQUEST_FAILURE';

export const MESSAGE_TEMPLATE_UPSERT_REQUEST = 'MESSAGE_TEMPLATE_UPSERT_REQUEST';
export const MESSAGE_TEMPLATE_UPSERT_REQUEST_SUCCESS = 'MESSAGE_TEMPLATE_UPSERT_REQUEST_SUCCESS';
export const MESSAGE_TEMPLATE_UPSERT_REQUEST_FAILURE = 'MESSAGE_TEMPLATE_UPSERT_REQUEST_FAILURE';

export const SETTINGS_FETCH_REQUEST = 'SETTINGS_FETCH_REQUEST';
export const SETTINGS_FETCH_REQUEST_SUCCESS = 'SETTINGS_FETCH_REQUEST_SUCCESS';
export const SETTINGS_FETCH_REQUEST_FAILURE = 'SETTINGS_FETCH_REQUEST_FAILURE';

export const SINGLE_MESSAGE_FETCH_REQUEST = 'SINGLE_MESSAGE_FETCH_REQUEST';
export const SINGLE_MESSAGE_FETCH_REQUEST_SUCCESS = 'SINGLE_MESSAGE_FETCH_REQUEST_SUCCESS';
export const SINGLE_MESSAGE_FETCH_REQUEST_FAILURE = 'SINGLE_MESSAGE_FETCH_REQUEST_FAILURE';

export const MESSAGE_UNREAD_COUNT_FETCH_REQUEST = 'MESSAGE_UNREAD_COUNT_FETCH_REQUEST';
export const MESSAGE_UNREAD_COUNT_FETCH_REQUEST_SUCCESS =
  'MESSAGE_UNREAD_COUNT_FETCH_REQUEST_SUCCESS';
export const MESSAGE_UNREAD_COUNT_FETCH_REQUEST_FAILURE =
  'MESSAGE_UNREAD_COUNT_FETCH_REQUEST_FAILURE';

export const EMAIL_TEMPLATE_TYPE_FETCH_REQUEST = 'EMAIL_TEMPLATE_TYPE_FETCH_REQUEST';
export const EMAIL_TEMPLATE_TYPE_FETCH_REQUEST_SUCCESS =
  'EMAIL_TEMPLATE_TYPE_FETCH_REQUEST_SUCCESS';
export const EMAIL_TEMPLATE_TYPE_FETCH_REQUEST_FAILURE =
  'EMAIL_TEMPLATE_TYPE_FETCH_REQUEST_FAILURE';

export const EMAIL_TEMPLATE_FIELD_FETCH_REQUEST = 'EMAIL_TEMPLATE_FIELD_FETCH_REQUEST';
export const EMAIL_TEMPLATE_FIELD_FETCH_REQUEST_SUCCESS =
  'EMAIL_TEMPLATE_FIELD_FETCH_REQUEST_SUCCESS';
export const EMAIL_TEMPLATE_FIELD_FETCH_REQUEST_FAILURE =
  'EMAIL_TEMPLATE_FIELD_FETCH_REQUEST_FAILURE';

export const FETCH_DRAFT_MESSAGES = 'FETCH_DRAFT_MESSAGES';
export const FETCH_DRAFT_MESSAGES_SUCCESS = 'FETCH_DRAFT_MESSAGES_SUCCESS';
export const FETCH_DRAFT_MESSAGES_FAILURE = 'FETCH_DRAFT_MESSAGES_FAILURE';

export const SINGLE_DRAFT_MESSAGE_FETCH_REQUEST = 'SINGLE_DRAFT_MESSAGE_FETCH_REQUEST';
export const SINGLE_DRAFT_MESSAGE_FETCH_REQUEST_SUCCESS =
  'SINGLE_DRAFT_MESSAGE_FETCH_REQUEST_SUCCESS';
export const SINGLE_DRAFT_MESSAGE_FETCH_REQUEST_FAILURE =
  'SINGLE_DRAFT_MESSAGE_FETCH_REQUEST_FAILURE';

export const DRAFT_MESSAGE_UPSERT_REQUEST = 'DRAFT_MESSAGE_UPSERT_REQUEST';
export const DRAFT_MESSAGE_UPSERT_REQUEST_SUCCESS = 'DRAFT_MESSAGE_UPSERT_REQUEST_SUCCESS';
export const DRAFT_MESSAGE_UPSERT_REQUEST_FAILURE = 'DRAFT_MESSAGE_UPSERT_REQUEST_FAILURE';

export const DRAFT_MESSAGE_DELETE_REQUEST = 'DRAFT_MESSAGE_DELETE_REQUEST';
export const DRAFT_MESSAGE_DELETE_REQUEST_SUCCESS = 'DRAFT_MESSAGE_DELETE_REQUEST_SUCCESS';
export const DRAFT_MESSAGE_DELETE_REQUEST_FAILURE = 'DRAFT_MESSAGE_DELETE_REQUEST_FAILURE';

export const DRAFT_MESSAGE_CLEAN_REQUEST = 'DRAFT_MESSAGE_CLEAN_REQUEST';

export const SINGLE_DRAFT_MESSAGE_CLEAN_REQUEST = 'SINGLE_DRAFT_MESSAGE_CLEAN_REQUEST';

export const FETCH_REPLY_MESSAGES_REQUEST = 'FETCH_REPLY_MESSAGES_REQUEST';
export const FETCH_REPLY_MESSAGES_REQUEST_SUCCESS = 'FETCH_REPLY_MESSAGES_REQUEST_SUCCESS';
export const FETCH_REPLY_MESSAGES_REQUEST_FAILURE = 'FETCH_REPLY_MESSAGES_REQUEST_FAILURE';

export const CLEAN_THREAD_MESSAGE_REPLY = 'CLEAN_THREAD_MESSAGE_REPLY';
export const MARK_MESSAGE_AS_UNREAD = 'MARK_MESSAGE_AS_UNREAD';
export const MARK_MESSAGE_AS_UNREAD_SUCCESS = 'MARK_MESSAGE_AS_UNREAD_SUCCESS';
export const MARK_MESSAGE_AS_UNREAD_FAILURE = 'MARK_MESSAGE_AS_UNREAD_FAILURE';

export const MESSAGE_ATTACHMENTS_DOWNLOAD_BULK_REQUEST =
  'MESSAGE_ATTACHMENTS_DOWNLOAD_BULK_REQUEST';
export const MESSAGE_ATTACHMENTS_DOWNLOAD_BULK_REQUEST_SUCCESS =
  'MESSAGE_ATTACHMENTS_DOWNLOAD_BULK_REQUEST_SUCCESS';
export const MESSAGE_ATTACHMENTS_DOWNLOAD_BULK_REQUEST_FAILURE =
  'DRAFT_MESSAGE_DELETE_REQUEST_FAILURE';
