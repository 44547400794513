export const TICKET_CLEAN_REQUEST = 'TICKET_CLEAN_REQUEST';
export const CONVERSATION_CLEAN_REQUEST = 'CONVERSATION_CLEAN_REQUEST';

export const TICKET_UPSERT_REQUEST = 'TICKET_UPSERT_REQUEST';
export const TICKET_UPSERT_REQUEST_SUCCESS = 'TICKET_UPSERT_REQUEST_SUCCESS';
export const TICKET_UPSERT_REQUEST_FAILURE = 'TICKET_UPSERT_REQUEST_FAILURE';

export const SINGLE_TICKET_FETCH_REQUEST = 'SINGLE_TICKET_FETCH_REQUEST';
export const SINGLE_TICKET_FETCH_REQUEST_SUCCESS = 'SINGLE_TICKET_FETCH_REQUEST_SUCCESS';
export const SINGLE_TICKET_FETCH_REQUEST_FAILURE = 'SINGLE_TICKET_FETCH_REQUEST_FAILURE';

export const TICKET_FETCH_REQUEST = 'TICKET_FETCH_REQUEST';
export const TICKET_FETCH_REQUEST_SUCCESS = 'TICKET_FETCH_REQUEST_SUCCESS';
export const TICKET_FETCH_REQUEST_FAILURE = 'TICKET_FETCH_REQUEST_FAILURE';

export const FETCH_STATUS_DROPDOWN_REQUEST = 'FETCH_STATUS_DROPDOWN_REQUEST';
export const FETCH_STATUS_DROPDOWN_REQUEST_SUCCESS = 'FETCH_STATUS_DROPDOWN_REQUEST_SUCCESS';
export const FETCH_STATUS_DROPDOWN_REQUEST_FAILURE = 'FETCH_STATUS_DROPDOWN_REQUEST_FAILURE';

export const FETCH_CONVERSATION_REQUEST = 'FETCH_CONVERSATION_REQUEST';
export const FETCH_CONVERSATION_REQUEST_SUCCESS = 'FETCH_CONVERSATION_REQUEST_SUCCESS';
export const FETCH_CONVERSATION_REQUEST_FAILURE = 'FETCH_CONVERSATION_REQUEST_FAILURE';

export const FETCH_CONVERSATION_REPLY_REQUEST = 'FETCH_CONVERSATION_REPLY_REQUEST';
export const FETCH_CONVERSATION_REPLY_REQUEST_SUCCESS = 'FETCH_CONVERSATION_REPLY_REQUEST_SUCCESS';
export const FETCH_CONVERSATION_REPLY_REQUEST_FAILURE = 'FETCH_CONVERSATION_REPLY_REQUEST_FAILURE';

export const UPSERT_CONVERSATIONS_REQUEST = 'UPSERT_CONVERSATIONS_REQUEST';
export const UPSERT_CONVERSATIONS_REQUEST_SUCCESS = 'UPSERT_CONVERSATIONS_REQUEST_SUCCESS';
export const UPSERT_CONVERSATIONS_REQUEST_FAILURE = 'UPSERT_CONVERSATIONS_REQUEST_FAILURE';

export const FETCH_UNREAD_COUNT_FOR_SUPPORT_TICKETS = 'FETCH_UNREAD_COUNT_FOR_SUPPORT_TICKETS';
export const FETCH_UNREAD_COUNT_FOR_SUPPORT_TICKETS_SUCCESS =
  'FETCH_UNREAD_COUNT_FOR_SUPPORT_TICKETS_SUCCESS';
export const FETCH_UNREAD_COUNT_FOR_SUPPORT_TICKETS_FAILURE =
  'FETCH_UNREAD_COUNT_FOR_SUPPORT_TICKETS_FAILURE';
