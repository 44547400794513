import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from './constants/appConfig';
import { BrowserTracing } from '@sentry/tracing';

/**
 * If the environment is production, initialize Sentry with the DSN, BrowserTracing, and
 * tracesSampleRate
 */
const InitializeSentry = () => {
  if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};

export default InitializeSentry;
