import axios from "axios"
import { API_URL } from "../constants/appConfig"

export const loggerFn = data => {
    axios.create({
        baseURL: `${API_URL}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Pragma: 'no-cache',
            'client-device': 'web',
        },
        responseType: 'json',
    }).post(`atom-manager/v1/public/logger`, {
        log: JSON.stringify(data),
        accessCode: "TEST",
        url: window.location.href
    })
}