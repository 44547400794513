import React, { Suspense } from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import * as Sentry from '@sentry/react';

// Main Page
import App from './pages/App';

import history from './utils/history';
import AuthProvider from './context/auth';
import configureStore from './store/configureStore';
import { PUBLIC_URL } from './constants/appConfig';

import './assets/scss/theme.scss';
import './assets/css/normalize.scss';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-dropzone-uploader/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { firebase_init } from './pushnotification';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { PDFJD_WORKER_CDN_URL } from './constants/appConfig';
import InitializeSentry from './sentryConfig';
import './i18n';

GlobalWorkerOptions.workerSrc = PDFJD_WORKER_CDN_URL; // Initialize the PDF.js worker

InitializeSentry();
export const mainStore = configureStore({}, history); // Initialize the store

render(
  <Suspense fallback={<div className="loading-container">Loading. Please wait...</div>}>
    <Provider store={mainStore}>
      <ConnectedRouter history={history}>
        <Router basename={PUBLIC_URL} history={history}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
      </ConnectedRouter>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

firebase_init();
