export const TICKET_CLEAN_REQUEST = 'TICKET_CLEAN_REQUEST_V2';

export const TICKET_UPSERT_REQUEST = 'TICKET_UPSERT_REQUEST_V2';
export const TICKET_UPSERT_REQUEST_SUCCESS = 'TICKET_UPSERT_REQUEST_V2_SUCCESS';
export const TICKET_UPSERT_REQUEST_FAILURE = 'TICKET_UPSERT_REQUEST_V2_FAILURE';

export const SINGLE_TICKET_FETCH_REQUEST = 'SINGLE_TICKET_FETCH_REQUEST_V2';
export const SINGLE_TICKET_FETCH_REQUEST_SUCCESS = 'SINGLE_TICKET_FETCH_REQUEST_V2_SUCCESS';
export const SINGLE_TICKET_FETCH_REQUEST_FAILURE = 'SINGLE_TICKET_FETCH_REQUEST_V2_FAILURE';

export const TICKET_FETCH_REQUEST = 'TICKET_FETCH_REQUEST_V2';
export const TICKET_FETCH_REQUEST_SUCCESS = 'TICKET_FETCH_REQUEST_V2_SUCCESS';
export const TICKET_FETCH_REQUEST_FAILURE = 'TICKET_FETCH_REQUEST_V2_FAILURE';

export const FETCH_STATUS_DROPDOWN_REQUEST = 'FETCH_STATUS_DROPDOWN_REQUEST_V2';
export const FETCH_STATUS_DROPDOWN_REQUEST_SUCCESS = 'FETCH_STATUS_DROPDOWN_REQUEST_V2_SUCCESS';
export const FETCH_STATUS_DROPDOWN_REQUEST_FAILURE = 'FETCH_STATUS_DROPDOWN_REQUEST_V2_FAILURE';

export const FETCH_COMMENT_REQUEST = 'FETCH_COMMENT_REQUEST';
export const FETCH_COMMENT_REQUEST_SUCCESS = 'FETCH_COMMENT_REQUEST_SUCCESS';
export const FETCH_COMMENT_REQUEST_FAILURE = 'FETCH_COMMENT_REQUEST_FAILURE';
