import firebase from 'firebase/app';
import 'firebase/messaging';
import { NOTIFICATION_CONST, PUBLIC_URL } from './constants/appConfig';
import { isBrowserSafari } from './utils/commonUtil';
import { adminFirebase } from './FirebaseAdminConfig';
import { clientFirebase } from './FirebaseCLientConfig';
import { NotificationToast } from './components/shared/Toast';

/**
 * If the browser is not Safari, then add a listener to the service worker to listen for messages, and
 * if the message is received, then display a notification.
 *
 * If the adminFirebase app is loaded, then listen for messages from the adminFirebase messaging
 * service.
 *
 * If the clientFirebase app is loaded, then listen for messages from the clientFirebase messaging
 * service.
 */
const activateNotification = () => {
  if (!isBrowserSafari() && firebase.messaging.isSupported()) {
    navigator.serviceWorker.addEventListener('message', message => {
      NotificationToast(message?.data?.data, 'INFO');
    });

    if (adminFirebase.firebase_.apps.length) {
      adminFirebase
        .messaging()
        .onMessage(payload => console.info('Message received. admin ', payload));
    }

    if (clientFirebase.firebase_.apps.length) {
      clientFirebase
        .messaging()
        .onMessage(payload => console.info('Message received. client', payload));
    }
  }
};

/**
 * If the browser supports service workers, register the service worker, and if the firebase app is
 * initialized, get the token.
 */
const initializeAdminFirebaseSW = () => {
  if (Notification.permission === NOTIFICATION_CONST.DEFAULT) {
    Notification.requestPermission().then(notificationRequestResponse => {
      if (notificationRequestResponse === NOTIFICATION_CONST.GRANTED) {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker
            .register(`${PUBLIC_URL}/firebase-messaging-sw.js`)
            .then(registration => {
              if (adminFirebase.firebase_.apps.length) {
                adminFirebase.messaging().getToken(registration);
              }
            });
        }
      }
    });
  }
};

export const adminAppUnregisterFirebase = () => {
  if ('serviceWorker' in navigator) {
    try {
      if (adminFirebase.firebase_.apps.length) {
        adminFirebase
          .messaging()
          .deleteToken()
          .catch(error => {
            process.env.NODE_ENV !== 'production' && console.error(error);
          });
      }
    } catch (error) {
      process.env.NODE_ENV !== 'production' && console.error(error);
    }
  }
};

export const adminAppAskForPermissionToReceiveNotifications = async () => {
  try {
    if (adminFirebase.firebase_.apps.length) {
      const messaging = adminFirebase.messaging();
      const token = await messaging.getToken();
      return token;
    }
  } catch (error) {
    process.env.NODE_ENV !== 'production' && console.error(error);
  }
};

/**
 * If the browser supports service workers, register the service worker, and if the firebase app is
 * initialized, get the token.
 */
const initializeClientFirebaseSW = () => {
  if (Notification.permission === NOTIFICATION_CONST.DEFAULT) {
    Notification.requestPermission().then(notificationRequestResponse => {
      if (notificationRequestResponse === NOTIFICATION_CONST.GRANTED) {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker
            .register(`${PUBLIC_URL}/firebase-messaging-sw.js`)
            .then(registration => {
              if (clientFirebase.firebase_.apps.length) {
                clientFirebase.messaging().getToken(registration);
              }
            });
        }
      }
    });
  }
};

export const clientAppAskForPermissionToReceiveNotifications = async () => {
  try {
    if (clientFirebase.firebase_.apps.length) {
      const messaging = clientFirebase.messaging();
      const token = await messaging.getToken();
      return token;
    }
  } catch (error) {
    process.env.NODE_ENV !== 'production' && console.error(error);
  }
};

export const clientAppUnregisterFirebase = () => {
  if ('serviceWorker' in navigator) {
    try {
      if (clientFirebase.firebase_.apps.length) {
        clientFirebase
          .messaging()
          .deleteToken()
          .catch(error => {
            process.env.NODE_ENV !== 'production' && console.error(error);
          });
      }
    } catch (error) {
      process.env.NODE_ENV !== 'production' && console.error(error);
    }
  }
};
/**
 * If the browser is a service worker, initialize the admin firebase, initialize the client firebase,
 * and activate the notification.
 */
export const firebase_init = () => {
  initializeAdminFirebaseSW();
  initializeClientFirebaseSW();
  activateNotification();
};
