import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export const firebaseAdminConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_ADMIN_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ADMIN_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ADMIN_FIREBASE_MESAUREMENT_APP_ID,
};

const adminFirebase = firebase.initializeApp(firebaseAdminConfig, 'admin');

const adminFirestore = adminFirebase.firestore();
const adminAuth = adminFirebase.auth();
const isFirebaseSupported = () => firebase.messaging.isSupported();

export { adminFirebase, adminAuth, adminFirestore, isFirebaseSupported };
