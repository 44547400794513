import { SystemAuthContext, SystemAuthProvider } from './system';
import { AdminAuthProvider, AdminAuthContext } from './admin';
import { ClientAuthContext, ClientAuthProvider } from './client';

const AuthProvider = ({ children }) => {
  return (
    <SystemAuthProvider>
      <AdminAuthProvider>
        <ClientAuthProvider>{children}</ClientAuthProvider>
      </AdminAuthProvider>
    </SystemAuthProvider>
  );
};

export default AuthProvider;

export { SystemAuthContext, ClientAuthContext, AdminAuthContext };
