export const APPOINTMENT_CLEAN_REQUEST = 'APPOINTMENT_CLEAN_REQUEST';

export const APPOINTMENT_FETCH_REQUEST = 'APPOINTMENT_FETCH_REQUEST';
export const APPOINTMENT_FETCH_REQUEST_SUCCESS = 'APPOINTMENT_FETCH_REQUEST_SUCCESS';
export const APPOINTMENT_FETCH_REQUEST_FAILURE = 'APPOINTMENT_FETCH_REQUEST_FAILURE';

export const SINGLE_APPOINTMENT_FETCH_REQUEST = 'SINGLE_APPOINTMENT_FETCH_REQUEST';
export const SINGLE_APPOINTMENT_FETCH_REQUEST_SUCCESS = 'SINGLE_APPOINTMENT_FETCH_REQUEST_SUCCESS';
export const SINGLE_APPOINTMENT_FETCH_REQUEST_FAILURE = 'SINGLE_APPOINTMENT_FETCH_REQUEST_FAILURE';

export const SINGLE_APPOINTMENT_ADD_REQUEST = 'SINGLE_APPOINTMENT_ADD_REQUEST';
export const SINGLE_APPOINTMENT_ADD_REQUEST_SUCCESS = 'SINGLE_APPOINTMENT_ADD_REQUEST_SUCCESS';
export const SINGLE_APPOINTMENT_ADD_REQUEST_FAILURE = 'SINGLE_APPOINTMENT_ADD_REQUEST_FAILURE';

export const SINGLE_APPOINTMENT_UPDATE_REQUEST = 'SINGLE_APPOINTMENT_UPDATE_REQUEST';
export const SINGLE_APPOINTMENT_UPDATE_REQUEST_SUCCESS =
  'SINGLE_APPOINTMENT_UPDATE_REQUEST_SUCCESS';
export const SINGLE_APPOINTMENT_UPDATE_REQUEST_FAILURE =
  'SINGLE_APPOINTMENT_UPDATE_REQUEST_FAILURE';

export const SINGLE_APPOINTMENT_CLEAN_REQUEST = 'SINGLE_APPOINTMENT_CLEAN_REQUEST';
export const APPOINTMENT_ERRORS_CLEAN_REQUEST = 'APPOINTMENT_ERRORS_CLEAN_REQUEST';

export const APPOINTMENT_SLOT_CLEAN_REQUEST = 'APPOINTMENT_SLOT_CLEAN_REQUEST';

export const APPOINTMENT_SLOT_FETCH_REQUEST = 'APPOINTMENT_SLOT_FETCH_REQUEST';
export const APPOINTMENT_SLOT_FETCH_REQUEST_SUCCESS = 'APPOINTMENT_SLOT_FETCH_REQUEST_SUCCESS';
export const APPOINTMENT_SLOT_FETCH_REQUEST_FAILURE = 'APPOINTMENT_SLOT_FETCH_REQUEST_FAILURE';

export const NEW_APPOINTMENT_FETCH_REQUEST = 'NEW_APPOINTMENT_FETCH_REQUEST';
export const NEW_APPOINTMENT_FETCH_REQUEST_SUCCESS = 'NEW_APPOINTMENT_FETCH_REQUEST_SUCCESS';
export const NEW_APPOINTMENT_FETCH_REQUEST_FAILURE = 'NEW_APPOINTMENT_FETCH_REQUEST_FAILURE';

export const APPOINTMENT_VALIDATION_CHECK_REQUEST = 'APPOINTMENT_VALIDATION_CHECK_REQUEST';
export const APPOINTMENT_VALIDATION_CHECK_REQUEST_SUCCESS =
  'APPOINTMENT_VALIDATION_CHECK_REQUEST_SUCCESS';
export const APPOINTMENT_VALIDATION_CHECK_REQUEST_FAILURE =
  'APPOINTMENT_VALIDATION_CHECK_REQUEST_FAILURE';
export const APPOINTMENT_VALIDATION_CHECK_CLEAN_REQUEST =
  'APPOINTMENT_VALIDATION_CHECK_CLEAN_REQUEST';
