export const DASHBOARD_CLEAN_REQUEST = 'DASHBOARD_CLEAN_REQUEST';

export const DASHBOARD_FETCH_REQUEST = 'DASHBOARD_FETCH_REQUEST';
export const DASHBOARD_FETCH_REQUEST_SUCCESS = 'DASHBOARD_FETCH_REQUEST_SUCCESS';
export const DASHBOARD_FETCH_REQUEST_FAILURE = 'DASHBOARD_FETCH_REQUEST_FAILURE';

export const DASHBOARD_TASK_COUNT_FETCH_REQUEST = 'DASHBOARD_TASK_COUNT_FETCH_REQUEST';
export const DASHBOARD_TASK_COUNT_FETCH_REQUEST_SUCCESS =
  'DASHBOARD_TASK_COUNT_FETCH_REQUEST_SUCCESS';
export const DASHBOARD_TASK_COUNT_FETCH_REQUEST_FAILURE =
  'DASHBOARD_TASK_COUNT_FETCH_REQUEST_FAILURE';

export const DASHBOARD_TASK_COUNT_ASSIGNED_BY_FETCH_REQUEST =
  'DASHBOARD_TASK_COUNT_ASSIGNED_BY_FETCH_REQUEST';
export const DASHBOARD_TASK_COUNT_ASSIGNED_BY_FETCH_REQUEST_SUCCESS =
  'DASHBOARD_TASK_COUNT_ASSIGNED_BY_FETCH_REQUEST_SUCCESS';
export const DASHBOARD_TASK_COUNT_ASSIGNED_BY_FETCH_REQUEST_FAILURE =
  'DASHBOARD_TASK_COUNT_ASSIGNED_BY_FETCH_REQUEST_FAILURE';

export const DASHBOARD_APPOINTMENT_COUNT_FETCH_REQUEST =
  'DASHBOARD_APPOINTMENT_COUNT_FETCH_REQUEST';
export const DASHBOARD_APPOINTMENT_COUNT_FETCH_REQUEST_SUCCESS =
  'DASHBOARD_APPOINTMENT_COUNT_FETCH_REQUEST_SUCCESS';
export const DASHBOARD_APPOINTMENT_COUNT_FETCH_REQUEST_FAILURE =
  'DASHBOARD_APPOINTMENT_COUNT_FETCH_REQUEST_FAILURE';

export const DASHBOARD_INVOICE_AMOUNT_FETCH_REQUEST = 'DASHBOARD_INVOICE_AMOUNT_FETCH_REQUEST';
export const DASHBOARD_INVOICE_AMOUNT_FETCH_REQUEST_SUCCESS =
  'DASHBOARD_INVOICE_AMOUNT_FETCH_REQUEST_SUCCESS';
export const DASHBOARD_INVOICE_AMOUNT_FETCH_REQUEST_FAILURE =
  'DASHBOARD_INVOICE_AMOUNT_FETCH_REQUEST_FAILURE';

export const DASHBOARD_INVOICE_SUMMARY_FETCH_REQUEST = 'DASHBOARD_INVOICE_SUMMARY_FETCH_REQUEST';
export const DASHBOARD_INVOICE_SUMMARY_FETCH_REQUEST_SUCCESS =
  'DASHBOARD_INVOICE_SUMMARY_FETCH_REQUEST_SUCCESS';
export const DASHBOARD_INVOICE_SUMMARY_FETCH_REQUEST_FAILURE =
  'DASHBOARD_INVOICE_SUMMARY_FETCH_REQUEST_FAILURE';

export const DASHBOARD_GOAL_FETCH_REQUEST = 'DASHBOARD_GOAL_FETCH_REQUEST';
export const DASHBOARD_GOAL_FETCH_REQUEST_SUCCESS = 'DASHBOARD_GOAL_FETCH_REQUEST_SUCCESS';
export const DASHBOARD_GOAL_FETCH_REQUEST_FAILURE = 'DASHBOARD_GOAL_FETCH_REQUEST_FAILURE';

export const DASHBOARD_UNREAD_MESSAGE_FETCH_REQUEST = 'DASHBOARD_UNREAD_MESSAGE_FETCH_REQUEST';
export const DASHBOARD_UNREAD_MESSAGE_FETCH_REQUEST_SUCCESS =
  'DASHBOARD_UNREAD_MESSAGE_FETCH_REQUEST_SUCCESS';
export const DASHBOARD_UNREAD_MESSAGE_FETCH_REQUEST_FAILURE =
  'DASHBOARD_UNREAD_MESSAGE_FETCH_REQUEST_FAILURE';

export const DASHBOARD_TASKBAR_FETCH_REQUEST = 'DASHBOARD_TASKBAR_FETCH_REQUEST';
export const DASHBOARD_TASKBAR_FETCH_REQUEST_SUCCESS = 'DASHBOARD_TASKBAR_FETCH_REQUEST_SUCCESS';
export const DASHBOARD_TASKBAR_FETCH_REQUEST_FAILURE = 'DASHBOARD_TASKBAR_FETCH_REQUEST_FAILURE';

export const DASHBOARD_TASK_SUMMARY_FETCH_REQUEST = 'DASHBOARD_TASK_SUMMARY_FETCH_REQUEST';
export const DASHBOARD_TASK_SUMMARY_FETCH_REQUEST_SUCCESS =
  'DASHBOARD_TASK_SUMMARY_FETCH_REQUEST_SUCCESS';
export const DASHBOARD_TASK_SUMMARY_FETCH_REQUEST_FAILURE =
  'DASHBOARD_TASK_SUMMARY_FETCH_REQUEST_FAILURE';

export const LANGUAGE_DROPDOWN_FETCH_REQUEST = 'LANGUAGE_DROPDOWN_FETCH_REQUEST';
export const LANGUAGE_DROPDOWN_FETCH_REQUEST_SUCCESS = 'LANGUAGE_DROPDOWN_FETCH_REQUEST_SUCCESS';
export const LANGUAGE_DROPDOWN_FETCH_REQUEST_FAILURE = 'LANGUAGE_DROPDOWN_FETCH_REQUEST_FAILURE';
